import React from "react";
import { HeroBlog, HighlightsPosts, Link, Posts, Tags } from "../../components";
import { Layout } from "../../layout";

export function Blog({ location, serverData }: any) {
	const { posts, tags } = serverData
	console.log(tags.results)
	return (
		<Layout location={location}>
			<HeroBlog />
			<Tags />
			<HighlightsPosts posts={posts.results} tags={tags.results} />
			<Posts data={posts.results} tags={tags.results} />
		</Layout>
	)
}

export async function getServerData() {
	const response = await fetch(`https://api.hubapi.com/cms/v3/blogs/posts?hapikey=${process.env.GATSBY_HUBSPOT_API_KEY}`)
	const responseTags = await fetch(`https://api.hubapi.com/cms/v3/blogs/tags?hapikey=${process.env.GATSBY_HUBSPOT_API_KEY}`)

	const data = await response.json()
	const dataTags = await responseTags.json()
	return {
		props: {
			posts: data,
			tags: dataTags
		}
	}
}

export default Blog